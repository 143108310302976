import React, { useState, useEffect } from "react";
import { Spin } from "antd";

const LAZY_POPOVER_DELAY = 500;

const LazyPopoverContent = React.memo(({ record, recordType }) => {
  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState(null);

  useEffect(() => {
    const records = recordType === 'unsigned' ? record.unsignedCrfs : record.signedCrfs;
    if (records?.length > 0) {
      setTimeout(() => {
        setContent(
          <ul>
            {records.map((crf, index) => (
              <li key={index}>{crf}</li>
            ))}
          </ul>,
        );
        setLoading(false);
      }, LAZY_POPOVER_DELAY);
    }
  }, [record, recordType]);

  return loading ? <Spin size="small" /> : content;
});

export default LazyPopoverContent;