import React from 'react';

const StatusSummary = ({
  signedCount,
  signedButChangedCount,
  unSignedCount,
  totalSubjects,
}) => (
  <>
    <h4>Status</h4>
    <ul>
      <li>Signed & Verified Subjects: {signedCount}</li>
      <li>
        Signed but data has changed. Requires re-signing:
        {signedButChangedCount}
      </li>
      <li>Unsigned Subjects: {unSignedCount}</li>
      <li>Total Subjects: {totalSubjects}</li>
    </ul>
  </>
);

export default StatusSummary;