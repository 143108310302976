import { getCsrf, getApplicationEnvironment } from '../../legacy/LegacyFacade';
import { handleFetch } from './fetchHandler';

const { rest: restapiBaseUrl } = getApplicationEnvironment();

export const RestapiBaseFetchConfig = {
  headers: {
    'Content-Type': 'application/json; charset=UTF-8',
    'Accept': 'application/json',
    'CSRF-Token': getCsrf(),
  },
  credentials: 'include',
};

export const getFieldReviews = async (context) => {
  const getFieldReviewsConfig = {
    method: 'POST',
    path: `/api/protocol/${context.protocolId}/site/${context.siteId}/subject/${context.subjectId}/fieldReviews`,
    body: { context }
  };
  
  const json = await baseRestApiHttFetcher(getFieldReviewsConfig);

  return json.items;
};

export const createFieldReview = async (context, action) => {
  const createFieldReviewsConfig = {
    method: 'POST',
    path: `/api/protocol/${context.protocolId}/site/${context.siteId}/subject/${context.subjectId}/fieldReview`,
    body: { 
      context,
      actions: [
        action
      ] 
    }
  };
  
  const json = await baseRestApiHttFetcher(createFieldReviewsConfig);

  return json.items;
};

export const updateFieldReview = async (context, etag, action) => {
  const updateFieldReviewsConfig = {
    method: 'PATCH',
    path: `/api/protocol/${context.protocolId}/site/${context.siteId}/subject/${context.subjectId}/fieldReviews/${context.fieldReviewId}`,
    body: { 
      context,
      etag,
      actions: [
        action
      ] 
    }
  };
  
  const json = await baseRestApiHttFetcher(updateFieldReviewsConfig);

  return json.items;
};

export const getSubjects = async (context) => {
  const getSubjectsConfig = {
    method: 'GET',
    path: `/stream/protocol/${context.protocolId}/site/${context.siteId}/subjects`,
  };

  const json = await baseRestApiHttFetcher(getSubjectsConfig);

  return json.items;
};

export const baseRestApiHttFetcher = async (requestConfig) => {
  const { method, path, body } = requestConfig;

  const response = await handleFetch({
    endpoint: restapiBaseUrl + path,
    method: method,
    body: JSON.stringify(body),
    ...RestapiBaseFetchConfig
  });

  return await response.json();
};

export const createPiSignatureUserKey = async (context) => {
  const createPiSignatureUserKeyConfig = {
    method: 'POST',
    path: `/api/protocol/${context.protocolId}/site/${context.siteId}/piSignatureUserKey`,
    body: {
      password: context.password,
    }
  };

  const json = await baseRestApiHttFetcher(createPiSignatureUserKeyConfig);

  return json;
};

export const piSignatureSignoffForSubject = async (context) => {
  const piSignatureSignoff = {
    method: 'PUT',
    path: `/api/protocol/${context.protocolId}/site/${context.siteId}/piSignatureSignoff`,
    body: {
      password: context.password,
      signerText: context.signerText,
      pisigDateUpdatedPerSubject: context.pisigDateUpdatedPerSubject,
    }
  };

  const json = await baseRestApiHttFetcher(piSignatureSignoff);

  return json;
};