import React from "react";
import { Button } from "antd";
import PropTypes from "prop-types";
import "./CustomFilterDropdown.scss";

const filterOptions = [
  { label: "Signed", value: "Signed & Verified" },
  { label: "Signed but data has changed. Requires re-signing", value: "Signed but data has changed. Requires re-signing" },
  { label: "Unsigned", value: "Unsigned" },
  { label: "Other", value: "Other" },
];

const CustomFilterDropdown = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  setActiveFilters,
}) => {
  const handleReset = () => {
    clearFilters();
    setActiveFilters((prev) => ({
      ...prev,
      status: [],
    }));
  };

  const handleOk = () => {
    requestAnimationFrame(() => {
      setActiveFilters((prev) => ({
        ...prev,
        status: selectedKeys,
      }));
      confirm();
    });
  };

  const handleCheckboxChange = (value) => {
    const newKeys = selectedKeys.includes(value)
      ? selectedKeys.filter((k) => k !== value)
      : [...selectedKeys, value];
    setSelectedKeys(newKeys);
  };

  return (
    <div className="site-pi-sig-content custom-filter-dropdown-wrapper">
      <div className="filter-dropdown">
        <div className="filter-options">
          {filterOptions.map((option) => (
            <div key={option.value}>
              <input
                type="checkbox"
                checked={selectedKeys.includes(option.value)}
                onChange={() => handleCheckboxChange(option.value)}
              />
              <span className="checkbox-label">{option.label}</span>
            </div>
          ))}
        </div>
        <Button
          type="primary"
          onClick={handleOk}
          size="small"
          className="button-with-margin"
        >
          OK
        </Button>
        <Button onClick={handleReset} size="small" >
          Reset
        </Button>
      </div>
    </div>
  );
};

CustomFilterDropdown.propTypes = {
  setSelectedKeys: PropTypes.func.isRequired,
  selectedKeys: PropTypes.array.isRequired,
  confirm: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  setActiveFilters: PropTypes.func.isRequired,
};

export default CustomFilterDropdown;