import React from "react";
import {
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import "./SignatureDetails.scss";

const SignatureDetails = ({
  status,
  signedCount,
  errorList,
  unSignedCount,
}) => {

  return (
    <div className="signature-details-wrapper">
      <h4 id="pisig-status">
        Status:
        <span> {status}</span>
        &nbsp;
      </h4>
      <div className="row">
        <div className="col-md-offset-0">
          <div className="panel panel-default">
            <div className="panel-body">
              {status === "Signed & Verified" ? (
                <>
                  <p id="pisig-signed-data">
                    <span
                      className="glyphicon glyphicon-certificate custom-glyphicon-certificate"
                      aria-hidden="true"
                    />
                    &nbsp;
                    <span className="success-text">PI Signature Success</span>
                  </p>
                  <p>{signedCount} subjects were signed.</p>
                </>
              ) : (
                <>
                  <p id="pisig-signed-data">
                    <ExclamationCircleOutlined className="error-icon" />
                    &nbsp;
                    <span className="error-text">PI Signature Failed</span>
                  </p>
                  <p>{signedCount} subjects were signed.</p>
                  <p>
                    {unSignedCount} subjects remain unsigned. There was an error
                     when processing signature. Please refresh the page and try again.
                  </p>
                  <ul>
                    {errorList.map((error, index) => (
                      <li key={index}>{error}</li>
                    ))}
                  </ul>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignatureDetails;